import { differenceInYears, format } from 'date-fns';

export const userRole = {
    client: 'client',
    specialist: 'specialist',
    admin: 'admin',
};

export const SPECIALIST_STATUS = {
    ACTIVE: 1,
    NOT_ACTIVE: 0,
};

export const specialistCategory = {
    lawyer: {
        key: 'lawyer',
        label: 'Адвокат',
    },
    notary: {
        key: 'notary',
        label: 'Нотариус',
    },
    patent_attorney: {
        key: 'patent_attorney',
        label: 'Патентный поверенный',
    },
    interpreter: {
        key: 'interpreter',
        label: 'Переводчик',
    },
    forensic_expert: {
        key: 'forensic_expert',
        label: 'Судебный эксперт',
    },
};

export const pluralizeReviews = quantity => {
    if (!quantity && isNaN(quantity)) {
        return;
    }

    quantity = quantity % 100;
    if (quantity >= 11 && quantity <= 19) {
        return `${quantity} отзывов`;
    } else {
        quantity = quantity % 10;
        if (quantity == 1) {
            return `${quantity} отзыв`;
        } else if (quantity >= 2 && quantity <= 4) {
            return `${quantity} отзыва`;
        } else {
            return `${quantity} отзывов`;
        }
    }
};

export const pluralizeYears = date => {
    if (!date) {
        return;
    }
    const titles = ['год', 'года', 'лет'];
    const birthDate = new Date(date);
    const years = differenceInYears(new Date(), birthDate);

    const cases = [2, 0, 1, 1, 1, 2];

    return `${years} ${titles[years % 100 > 4 && years % 100 < 20 ? 2 : cases[years % 10 < 5 ? years % 10 : 5]]}`;
};

export const formatWorkExperience = date => {
    if (!date) {
        return;
    }
    const formattedDate = format(new Date(date), 'dd.MM.yyyy');

    return `с ${formattedDate} (${pluralizeYears(date)})`;
};

export const verificationStatuses = {
    new: 'new',
    under_review: 'under_review',
    not_verified: 'not_verified',
    verified: 'verified',
};
