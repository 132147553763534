const PAGE_INFO_ABOUT_APPLICANT = 'PAGE_INFO_ABOUT_APPLICANT';
const PAGE_REQUEST_PARAMETERS = 'PAGE_REQUEST_PARAMETERS';
const PAGE_DATE_AND_TIME = 'PAGE_DATE_AND_TIME';
const PAGE_REQUEST_SUCCESS = 'PAGE_REQUEST_SUCCESS';

export const REQUEST__PAGES = {
    PAGE_INFO_ABOUT_APPLICANT,
    PAGE_REQUEST_PARAMETERS,
    PAGE_DATE_AND_TIME,
    PAGE_REQUEST_SUCCESS,
};

export const specialistRequestPages = [
    REQUEST__PAGES.PAGE_INFO_ABOUT_APPLICANT,
    REQUEST__PAGES.PAGE_REQUEST_PARAMETERS,
    REQUEST__PAGES.PAGE_DATE_AND_TIME,
    REQUEST__PAGES.PAGE_REQUEST_SUCCESS,
];