import { endOfMonth, endOfYear, format, startOfMonth, startOfYear } from 'date-fns';

export const options = {
    responsive: true,
    scales: {
        x: {
            barPercentage: 0,
            grid: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                autoSkip: false,
                maxRotation: 0,
                minRotation: 0,
                color: '#B0B3BC',
                font: {
                    family: 'ProximaNova-Semibold',
                    size: 12,
                    weight: '600',
                    lineHeight: 1.3,
                },
            },
        },
        y: {
            grid: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                color: '#B0B3BC',
                font: {
                    family: 'ProximaNova-Semibold',
                    size: 12,
                    weight: '600',
                    lineHeight: 1.3,
                },
            },
        },
    },
    plugins: {
        tooltip: {
            mode: 'index',
            intersect: true,
            backgroundColor: '#fff',
            titleColor: '#5A5C68',
            padding: 16,
            cornerRadius: 8,
            bodyColor: '#B0B3BC',
            usePointStyle: true,
            caretPadding: 0,
            caretSize: 0,
            boxPadding: 8,
            callbacks: {
                labelPointStyle: function (context) {
                    return {
                        pointStyle: 'circle',
                    };
                },
                label: function (context) {
                    let label = context.dataset.label || '';

                    if (label) {
                        label += ' ';
                    }
                    label += Math.round(context.parsed.y * 100) / 100;

                    return label;
                },
            },
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                boxWidth: 7,
                boxHeight: 7,
                usePointStyle: true,
                padding: 23,
                color: '#5A5C68',
                font: {
                    family: 'ProximaNova-Semibold',
                    size: 12,
                    weight: '600',
                    lineHeight: 1.3,
                },
            },
        },
    },
};

export const getChartData = dataArray => {
    const labels = dataArray.map(item => new Date(item.date));
    const data = dataArray.map(item => item.count);

    return { labels, data };
};

let currentYear = new Date().getFullYear();

export const periods = {
    month: {
        date_from: format(startOfYear(new Date(currentYear, 0)), 'dd.MM.yyyy HH:mm'),
        date_to: format(endOfYear(new Date(currentYear, 0)), 'dd.MM.yyyy HH:mm'),
        range: 'month',
        type: 'year',
    },
    day: {
        date_from: format(startOfMonth(new Date()), 'dd.MM.yyyy HH:mm'),
        date_to: format(endOfMonth(new Date()), 'dd.MM.yyyy HH:mm'),
        range: 'day',
        type: 'month',
    },
    period: {
        date_from: format(startOfMonth(new Date(currentYear, 0)), 'dd.MM.yyyy HH:mm'),
        date_to: format(endOfMonth(new Date(currentYear, 0)), 'dd.MM.yyyy HH:mm'),
        range: 'month',
        type: 'period',
    },
};

export const isDecimal = num => {
    return !Number.isInteger(num);
};