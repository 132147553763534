
const PAGE_INFO_ABOUT_APPLICANT = 'PAGE_INFO_ABOUT_APPLICANT';
const PAGE_REQUEST_PARAMETERS = 'PAGE_REQUEST_PARAMETERS';
const PAGE_DATE_AND_TIME = 'PAGE_DATE_AND_TIME';
const PAGE_REQUEST_SUCCESS = 'PAGE_REQUEST_SUCCESS';

export const MULTIPLE__PAGES = {
    PAGE_INFO_ABOUT_APPLICANT,
    PAGE_REQUEST_PARAMETERS,
    PAGE_DATE_AND_TIME,
    PAGE_REQUEST_SUCCESS,
};

export const specialistMultipleRequestPages = [
    MULTIPLE__PAGES.PAGE_INFO_ABOUT_APPLICANT,
    MULTIPLE__PAGES.PAGE_REQUEST_PARAMETERS,
    MULTIPLE__PAGES.PAGE_DATE_AND_TIME,
    MULTIPLE__PAGES.PAGE_REQUEST_SUCCESS,
];

export const initialValues = {
    middle_name: '',
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    question: '',
    title: '',
};
