import { format } from 'date-fns';

export const updateQueryParams = (queryParams, values) => {
    const newQueryParams = {
        ...queryParams,
        page: 1,
    };

    if (values.flt_category_specialist) {
        newQueryParams.flt_category_specialist = values.flt_category_specialist.value.toLowerCase();
    } else {
        delete newQueryParams.flt_category_specialist;
    }

    if (values.flt_region) {
        newQueryParams.flt_region = values.flt_region.value;
    } else {
        delete newQueryParams.flt_region;
    }

    if (values.flt_legal_case_category) {
        newQueryParams.flt_legal_case_category = values.flt_legal_case_category.value;
    } else {
        delete newQueryParams.flt_legal_case_category;
    }

    if (values.flt_law_education) {
        newQueryParams.flt_law_education = values.flt_law_education.value.toLowerCase();
    } else {
        delete newQueryParams.flt_law_education;
    }

    if (values.flt_work_experience) {
        newQueryParams.flt_work_experience = values.flt_work_experience;
    } else {
        delete newQueryParams.flt_work_experience;
    }

    if (values.flt_register_number) {
        newQueryParams.flt_register_number = values.flt_register_number;
    } else {
        delete newQueryParams.flt_register_number;
    }

    if (values.flt_certification_number) {
        newQueryParams.flt_certification_number = values.flt_certification_number;
    } else {
        delete newQueryParams.flt_certification_number;
    }

    if (values.flt_is_reward) {
        newQueryParams.flt_is_reward = values.flt_is_reward;
    } else {
        delete newQueryParams.flt_is_reward;
    }

    if (values.flt_is_photo) {
        newQueryParams.flt_is_photo = values.flt_is_photo;
    } else {
        delete newQueryParams.flt_is_reward;
    }

    if (values.date_from) {
        newQueryParams.date_from = format(values.date_from, 'dd.MM.yyyy HH:mm');
    } else {
        delete newQueryParams.date_from;
    }

    if (values.date_to) {
        newQueryParams.date_to = format(values.date_to, 'dd.MM.yyyy HH:mm');
    } else {
        delete newQueryParams.date_to;
    }

    if (values.legal_case_category) {
        newQueryParams.legal_case_category = values.legal_case_category.value;
    } else {
        delete newQueryParams.legal_case_category;
    }

    if (values.status) {
        newQueryParams.status = values.status;
    } else {
        delete newQueryParams.status;
    }
    // if (values.new) {
    //     newQueryParams.status = caseStatuses.new;
    // } else {
    //     delete newQueryParams.status;
    // }

    return newQueryParams;
};

export const checkValues = values => {
    for (let key in values) {
        if (values[key] !== undefined && values[key] !== null && values[key] !== false) {
            return true;
        }
    }

    return false;
};
