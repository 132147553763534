export const truncateCalendarName = str => {
    if (!str) {
        return;
    }
    if (str.length <= 50) {
        return str;
    }

    const lastDotIndex = str.lastIndexOf('.');
    const name = str.substring(0, lastDotIndex);
    const extension = str.substring(lastDotIndex);

    return name.length <= 50 ? str : name.substring(0, 50) + '..' + extension;
};

export const documentTabNames = {
    createNewDocument: 'Создание нового документа',
    existingDocuments: 'Выбрать из существующих документов',
};

export const pluralizeDocument = count => {
    let ost10 = count % 10;
    let ost100 = count % 100;

    if (ost10 === 1 && ost100 !== 11) {
        return count + ' документ';
    } else if (ost10 >= 2 && ost10 <= 4 && (ost100 < 10 || ost100 >= 20)) {
        return count + ' документа';
    } else {
        return count + ' документов';
    }
};