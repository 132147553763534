
export const receptionIntervalOptions = [
    { value: 15, label: '15 мин' },
    { value: 30, label: '30 мин' },
    { value: 60, label: '1 час' },
];

export const monthNames = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
];

export const dayNames = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

export const calendarViews = {
    day: 'day',
    week: 'week',
    month: 'month',
};

export const calendarViewsTabs = [
    {
        key: calendarViews.month,
        label: 'Месяц',
    },
    {
        key: calendarViews.week,
        label: 'Неделя',
    },
    {
        key: calendarViews.day,
        label: 'День',
    },
];

export const truncateString = (str, num) => {
    if (!str) {
        return;
    }
    if (str.length > num) {
        return str.slice(0, num) + '..';
    } else {
        return str;
    }
};

export const slotStatuses = {
    busy: 'busy',
    unavailable: 'unavailable',
};
