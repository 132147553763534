export const declensionForCases = quantity => {
    let expressions = ['дело', 'дела', 'дел'];
    let result;
    let count = quantity % 100;

    if (count >= 5 && count <= 20) {
        result = expressions[2];
    } else {
        count = count % 10;
        if (count === 1) {
            result = expressions[0];
        } else if (count >= 2 && count <= 4) {
            result = expressions[1];
        } else {
            result = expressions[2];
        }
    }

    return result;
};

export const pluralizeParticipant = count => {
    if (count === 1) {
        return `${count} участник`;
    } else if (count > 1 && count < 5) {
        return `${count} участника`;
    } else {
        return `${count} участников`;
    }
};

export const caseStatuses = {
    new: 'new',
    in_work: 'in_work',
    pending: 'pending',
    completed: 'completed',
};
